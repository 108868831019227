import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { PricingsComponent } from './components/pages/pricings/pricings.component';
import { ErrorComponent } from './components/pages/error/error.component';

const routes: Routes = [
  {path: '', component: HomeTwoComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'blog-details', component: BlogDetailsComponent},
  {path: 'pricings', component: PricingsComponent},
  {path: 'error', component: ErrorComponent},
  // Here add new pages component

  {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
