<app-navbar></app-navbar>

<section class="parallax-bg fixed-bg view-height-100vh lg-section"
    style="background-image: url('assets/images/wetransfer/banner.jpg')" id="home">
    <div class="parallax-overlay"></div>
    <div class="hero-text-wrap xxl-screen transparent-bg">
        <div class="hero-text">
            <div class="row">
                <div class="col-md-8 centerize-col">
                    <div class="all-padding-50 text-center white-color">
                        <div class="company-name">WAOBIT</div>
                        <h4 class="font-100 raleway-font">LABORATORIO DE MARKETING Y TRANSFORMACIÓN DIGITAL</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="white-bg xs-pb-100" id="features">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of features;" class="col-md-4 col-sm-4 col-xs-12 mb-30 hover-effect">
                <div [ngClass]="item.featureBox">
                  <img [src]="item.iconImage" alt="{{item.title}}" class="icon-img" />
                  <h4 class="mt-0 font-600">{{item.title}}</h4>
                  <p class="font-400">{{item.desc}}</p>
                </div>
              </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-6 col-sm-12">
                <div class="responsive-screen">
                    <img src="assets/images/wetransfer/features.jpg" class="img-responsive border-radius-25"
                        alt="features" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12 text-left d-flex align-items-center">
                <div class="d-block pl-30 sm-pl-0">
                    <div class="section-title mt-50">
                        <h2 class="raleway-font default-color mt-0 line-head">En Waobit, nos apasionamos por la tecnología y la
                            innovación.</h2>
                        <h1 class="raleway-font mt-30 font-50px font-300">¿Quiénes somos?</h1>
                    </div>
                    <p>Somos un equipo dedicado a ayudar a empresas a destacarse en el entorno digital. Con servicios
                        integrales en marketing digital, desarrollo tecnológico y mensajería masiva, ofrecemos
                        soluciones que impulsan el crecimiento y la transformación de nuestros clientes.</p>
                    <p>Nuestro objetivo es convertirnos en aliados estratégicos de nuestros clientes, proporcionándoles
                        soluciones integrales de marketing digital, desarrollo tecnológico y comunicación masiva que
                        impulsen su crecimiento.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="parallax-bg fixed-bg pt-80 pb-80"
    style="background-image: url('assets/images/background/parallax-bg.jpg')">
    <div class="overlay-bg"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-50 white-color text-center">
                    <h1 class="raleway-font remove-margin font-50px font-300">Procesos</h1>
                </div>
            </div>
        </div>
        <div class="row our-process-style-02">
            <div *ngFor="let step of steps" class="col-md-3 col-sm-6 col-xs-12 line xs-mb-30 sm-mb-30">
                <div class="icon-wrap white-bg">
                    <div class="icon">
                        <i [ngClass]="step.icon"></i>
                    </div>
                </div>
                <div class="text-center white-color">
                    <h4 class="font-500">{{step.title}}</h4>
                    <p class="mb-0">{{step.desc}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="gray-bg" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">Somos Waobit</h2>
                    <h1 class="raleway-font">Transformación Digital</h1>
                </div>
                <p>Por nuestro amplio conocimiento, creamos plataformas digitales en el lenguaje de desarrollo que más
                    le convenga a tu empresa.</p>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-7 col-xs-12">
                <img class="img-responsive" src="assets/images/responsive-screen-2.png" alt="laptop">
            </div>
            <div class="col-md-5 mt-30 col-xs-12">
                <div class="skillbar-wrap skillbar-style-01">
                    <div *ngFor="let skill of skills">
                        <div class="skillbar">
                            <span class="skillbar-title text-uppercase">{{ skill.name }}</span>
                            <p class="skillbar-bar gradient-bg" role="progressbar" [style.width]="skill.level + '%'"
                                [attr.aria-valuenow]="skill.level" aria-valuemin="0" aria-valuemax="100"></p>
                            <span class="skill-bar-percent">{{ skill.level }}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="grey-bg" id="portfolio">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">Soluciones integrales</h2>
                    <h1 class="raleway-font">Nuestro Portafolio de Servicios</h1>
                </div>
                <p>Ofrecemos servicios personalizados, adaptados a las necesidades específicas de cada cliente,
                    brindando asesoría integral para asegurar que cada decisión impulse resultados positivos y
                    sostenibles en su empresa.</p>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row mt-50">
            <div *ngFor="let portfolio of portfolios" class="col-md-3 col-sm-6 portfolio with-spacing">
                <div class="portfolio-item border-white-15">
                    <a href="javascript:void(0)">
                        <img src="assets/images/portfolio/grid/{{portfolio.img}}" alt="">
                        <div class="portfolio-info gradient-bg">
                            <div class="centrize">
                                <div class="v-center white-color">
                                    <h3>{{portfolio.title}}</h3>
                                    <p>{{portfolio.subTitle}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="parallax-bg fixed-bg" style="background-image: url('assets/images/wetransfer/business-man.jpg')">
    <div class="gradient-overlay-bg-two"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center parallax-content height-400px">
                <div class="center-layout">
                    <div class="v-align-middle">
                        <h1 class="font-400 white-color raleway-font xs-font-40px">Siente cómo tu negocio evoluciona al
                            ritmo de un mundo digital.</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="gradient-bg-two xs-pb-60" id="blog">
    <div class="col-md-6 col-sm-6 bg-flex bg-flex-right">
        <div class="bg-flex-holder bg-flex-cover" style="background-image: url(assets/images/wetransfer/blog.jpg);">
        </div>
    </div>
    <div class="container-fluid">
        <div class="col-md-6 col-sm-6">
            <div class="col-inner spacer white-color text-center">
                <h2 class="raleway-font mt-0 font-20px xs-font-17px">Lee nuestro blog</h2>
                <h1 class="raleway-font mt-0 font-50px font-300 xs-font-27px">¿Qué es la transformación Digital?</h1>
                <p>La transformación digital es un proceso que cambia por completo la forma en que una organización
                    ofrece valor a sus clientes…</p>
                <p><a routerLink="/blog-details" class="btn btn-md btn-square btn-light-outline mt-30">Ver detalles del
                        blog</a></p>
            </div>
        </div>
    </div>
</section>

<section class="white-bg transition-none" id="contact">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color line-head">¿Quieres que tu empresa evolucione transformándose
                        digitalmente?</h2>
                    <h1 class="raleway-font">Contáctanos ahora</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row mt-70">
            <div class="col-md-6 col-sm-6 col-xs-12 xs-mb-50">
                <div class="row mt-20">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Ubicación</h2>
                            <p class="mt-30">Guayaquil - Ecuador
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Contácto</h2>
                            <p class="mb-0 mt-30">+593 XX XXX XXXX</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Correo</h2>
                            <p class="mb-0 mt-30">info&#64;waobit.com</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="section-title">
                            <h2 class="raleway-font default-color">Soporte</h2>
                            <p class="mb-0 mt-30">soporte&#64;waobit.com</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-12 social-icons-style-01">
                        <ul class="md-icon left-icon">
                            <li *ngFor="let social of socialMedia">
                                <a [ngClass]="social.iconClass" href="javascript:void(0)"><i
                                        [ngClass]="social.icon"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <form (submit)="sendEmail($event)" class="contact-form-style-01" #contactForm="ngForm">
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="visually-hidden" for="user_name">Nombre</label>
                                <input type="text" minlength="2" maxlength="15"
                                    [class.is-invalid]="userName.invalid && userName.touched" name="user_name"
                                    class="md-input" id="name" placeholder="Nombre *" [(ngModel)]="formData.user_name"
                                    #userName="ngModel" required>
                                <div *ngIf="userName.invalid && userName.touched" class="invalid-feedback mb-10">
                                    Nombre es requerido.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="mb-3">
                                <label class="visually-hidden" for="user_email">Correo</label>
                                <input type="email" minlength="2" maxlength="35"
                                    [class.is-invalid]="userEmail.invalid && userEmail.touched" name="user_email"
                                    class="md-input" id="email" placeholder="Correo *" [(ngModel)]="formData.user_email"
                                    #userEmail="ngModel" required email>
                                <div *ngIf="userEmail.invalid && userEmail.touched" class="invalid-feedback mb-10">
                                    Un correo valido es requerido.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="form-group">
                                <label class="visually-hidden" for="user_company">Empresa</label>
                                <input type="text" minlength="2" maxlength="15"
                                    [class.is-invalid]="userCompany.invalid && userCompany.touched" name="user_company"
                                    class="md-input" id="company" placeholder="Empresa *"
                                    [(ngModel)]="formData.user_company" #userCompany="ngModel" required>
                                <div *ngIf="userCompany.invalid && userCompany.touched" class="invalid-feedback mb-10">
                                    Empresa es requerida.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="mb-3">
                                <label class="visually-hidden" for="user_phone">Teléfono</label>
                                <input type="phone" minlength="2" maxlength="35"
                                    [class.is-invalid]="userPhone.invalid && userPhone.touched" name="user_phone"
                                    class="md-input" id="phone" placeholder="Teléfono *"
                                    [(ngModel)]="formData.user_phone" #userPhone="ngModel" required>
                                <div *ngIf="userPhone.invalid && userPhone.touched" class="invalid-feedback mb-10">
                                    Un teléfono es requerido.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="mb-3">
                                <label class="visually-hidden" for="message">Detalles</label>
                                <textarea name="message" [class.is-invalid]="userMessage.invalid && userMessage.touched"
                                    class="md-textarea" id="message" rows="3" placeholder="Detalles"
                                    [(ngModel)]="formData.message" #userMessage="ngModel" required></textarea>
                                <div *ngIf="userMessage.invalid && userMessage.touched" class="invalid-feedback mb-10">
                                    Detalles son requeridos.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <div class="text-left mt-20">
                                <button type="submit" name="submit"
                                    class="btn btn-outline btn-md btn-circle btn-animate remove-margin"
                                    [disabled]="contactForm.invalid" value="Send">
                                    <span>Enviar mensaje <i class="ion-android-arrow-forward"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</section>