<footer class="footer">
  <div class="footer-copyright">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="copy-right text-left">
            © 2024 Waobit. Todos los derechos reservados
            <span> | </span>
            <a href="https://waobit.blob.core.windows.net/portal/files/politicas-de-proteccion-de-datos-2024.pdf" target="_blank" rel="noopener noreferrer">
              Protección de datos personales
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>